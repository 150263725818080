import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="Cargando" /> 
  </>
)

export default NotFoundPage
